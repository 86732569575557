import React from "react";

const SuccessModal = ({ setSuccess }) => {
  return (
    <div className="smodal-layout">
      <div className="terms-modal">
        <div className="modal-header">
          <h2>Successful</h2>
        </div>
        <div className="modal-body">
          <h3>You have successfully registered</h3>
        </div>
        <div className="terms-modal-footer">
          <button
            type="button"
            className="btn-primary"
            onClick={() => {
              setSuccess(false);
            }}
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;

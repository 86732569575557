import React from "react";

const Welcome = () => {
  return (
    <section className="welcome">
      <div className="container">
        <h2>SELAMAT DATANG DI ESPL</h2>
        <div className="grid">
          <div className="wcard">
            <div className="wcard-header">
              <span>01</span>
              <h3>TEMPAT BERMAIN GAME</h3>
            </div>
            <div className="wcard-body">
              <img src="/card1.png" alt="" />
              <div className="body-content">
                <p>
                  Di ESPL, Anda dapat bermain game, mencari teman baru, dan
                  bahkan bergabung dengan pembicaraan Akademi kami untuk
                  mempelajari lebih lanjut tentang Web 3.0, NFT, dan Pemasaran
                  Digital dari pakar industri.
                </p>
              </div>
            </div>
          </div>
          <div className="wcard">
            <div className="wcard-header">
              <span>02</span>
              <h3>hubungkan</h3>
            </div>
            <div className="wcard-body">
              <img src="/card2.png" alt="" />
              <div className="body-content">
                <p>
                  Anda akan menjadi bagian dari komunitas game bergengsi. Di
                  sinilah anda akan mendapatkan hadiah, hiburan, dan teman
                  terbaik, semuanya dalam satu! Apakah anda cukup bermain?
                </p>
              </div>
            </div>
          </div>
          <div className="wcard">
            <div className="wcard-header">
              <span>03</span>
              <h3>Pelajari dan Dapatkan Hadiah</h3>
            </div>
            <div className="wcard-body">
              <img src="/card3.png" alt="" />
              <div className="body-content">
                <p>
                  Untuk periode terbatas ini saja, kami akan memberikan produk
                  game senilai USD10.000 kepada pengguna terdaftar kami. Setiap
                  minggu, anda berkesempatan untuk menerima hadiah (senilai USD
                  730) - ESPL Gaming Chair, Keyboard Gaming ESPL atau Mouse
                  Gaming ESPL!
                </p>
              </div>
            </div>
          </div>
        </div>
        <p>
          Yang perlu Anda lakukan adalah mendaftar* seperti di bawah ini - Anda
          hanya perlu 3 menit!
        </p>
      </div>
    </section>
  );
};

export default Welcome;

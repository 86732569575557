import React, { useRef, useState } from "react";
import _ from "lodash";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { createUser } from "./Cognito.jsx";
import TermsModal from "./TermsModal.jsx";
import SuccessModal from "./SuccessModal.jsx";

const Contacts = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  //   const [country, setCountry] = React.useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const [termsModal, setTermsModal] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = (event) => {
    // console.log(`
    //       Email: ${email}
    //       Password:
    //       Country: "1756"
    //       Phone number:${phone}
    //       Accepted Terms: ${acceptedTerms}
    //     `);
    event.preventDefault();
    if (phone.length <= 2) return;
    createUser(
      `${email}`,
      `${password}`,
      `+${phone}`,
      "1756",
      `${username}`,
      (err, result) => {
        if (err) {
          //   console.log(err);
          setError(err.message);
          //   console.log(error);
          return;
        }
        setError("");
        // console.log(result.user);
        setSuccess(true);
        setUsername("");
        setEmail("");
        setAcceptedTerms(false);
        setPassword("");
        setPhone("+62");
      }
    );
  };

  const body = document.querySelector("body");
  if (termsModal || success) {
    body.classList.add("termsOpened");
  } else {
    body.classList.remove("termsOpened");
  }
  return (
    <section className="contacts" id="contact">
      <div className="container">
        <h2>Bergabunglah hari ini</h2>
        <form onSubmit={handleSubmit}>
          <input
            placeholder="Nama pengguna"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            placeholder="Alamat email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <input
            type="password"
            placeholder="Kata sandi"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <div className="phone-wrapper">
            <input
              className="hidden"
              type="tel"
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <ReactPhoneInput
              country={"id"}
              value={phone}
              placeholder="Nomor telepon"
              onlyCountries={["id", "my"]}
              inputExtraProps={{
                required: true,
                // autoFocus: true,
              }}
              //   isValid={(inputNumber, country, countries) => {
              //     return countries.some((country) => {
              //       return (
              //         _.startsWith(inputNumber, country.dialCode) ||
              //         _.startsWith(country.dialCode, inputNumber)
              //       );
              //     });
              //   }}
              onChange={(phone) => setPhone(phone)}
            />
          </div>
          <p>
            *Email dan nomor ponsel Anda akan digunakan untuk menghubungi Anda
            jika Anda adalah pemenang yang beruntung, dan juga untuk membuat
            Anda selalu mengetahui pembaruan ESPL paling keren, jadi pastikan
            Anda memasukkan detail yang benar 🙂
          </p>
          {error !== "" && <p className="errMessage">{error}</p>}

          {/* <select
          name="country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          required
        >
          <option key=""></option>
          {countries.map((country) => (
              <option key={country}>{country}</option>
            ))}

         
        </select> */}

          <div className="label">
            <input
              type="checkbox"
              checked={acceptedTerms}
              onChange={(e) => setAcceptedTerms(!acceptedTerms)}
              required
            />
            <p>
              Saya telah menyetujui{" "}
              <span onClick={() => setTermsModal(true)}>
                {" "}
                persyaratan dan layanan.
              </span>
            </p>
          </div>

          <button className="btn-primary">Mainkan!</button>
        </form>
      </div>
      {termsModal && (
        <TermsModal
          setTermsModal={setTermsModal}
          setAcceptedTerms={setAcceptedTerms}
        />
      )}
      {success && <SuccessModal setSuccess={setSuccess} />}
    </section>
  );
};

export default Contacts;

import React from "react";

const Hero = () => {
  return (
    <div className="hero">
      <div className="container-md">
        <header>
          <div className="logo-holder">
            <a rel="noreferrer" target="_blank" href="https://espl.gg/malaysia">
              <div className="logo1">
                <img src="/espl-logo.svg" alt="" />
              </div>
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.trisaktimultimedia.ac.id/"
            >
              <div className="logo2">
                <img src="/merah.svg" alt="" />
              </div>
            </a>
          </div>
        </header>
        <div className="content">
          <h1>
            <span>MAINKAN</span>
            <span className="seperator"></span>
            <span>HUBUNGKAN</span>
            <span className="seperator"></span>
            <span>PELAJARI</span>
          </h1>

          <a href="#contact" className="btn-primary">
            Bergabunglah
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;

import React from "react";

const TermsModal = ({ setAcceptedTerms, setTermsModal }) => {
  return (
    <div className="modal-layout">
      <div className="terms-modal">
        <div className="modal-header">
          <h3 className="modal-title">Terms of service</h3>
        </div>
        <div className="modal-body">
          <h2>
            <span>
              Preamble
              <hr />
              By accessing and using the website espl.gg (the “Website”) you
              agree and fully accept to these Terms of Service (the «Terms») of
              Esports Pte. Ltd, company, incorporated in the Singapore,
              registration number 201931405Z, 71 Ayer Rajah Crescent #06-06,
              Singapore 139951 (hereinafter “ESPL“, “Company” "we", or "us"),
              including the Privacy Policy which is hereby posted on the
              Website. If you do not agree to all of these terms, please do not
              use the Website or any information, links or content contained on
              the Website. Your access to and use of the Website constitutes
              your acceptance of and agreement to by each of the terms set forth
              below. If you are using the Website on behalf of any organization,
              or entity, or company (Esports Pte. Ltd) you represent and warrant
              that you are duly authorized to represent and bind the
              Organization to the Terms and you accept the Terms on behalf of
              such Organization.
              <hr />
              You acknowledge that you are at sufficient age to access the
              Website and you have carefully read, clearly understood and
              accepted the Terms to be bound by. You acknowledge that you use
              the Website and the Company services in accordance with the laws
              of your country of residence and any other applicable legal
              requirements.
              <hr />
              By continuing, and to the extent permitted by law, you are
              agreeing not to hold any of the Company and its respective past,
              present and future employees, officers, directors, contractors,
              consultants, equity holders, suppliers, vendors, service
              providers, parent companies, subsidiaries, affiliates, agents,
              representatives, predecessors, successors and assigns (the “Team”)
              liable for any losses or any special, incidental, or consequential
              damages arising from, or in any way connected, to the usage of
              ESPL services, including losses associated with the terms set
              forth below.
              <hr />
              These Terms of Service may be amended, modified and/or changed by
              the Company at any time on its sole discretion. Any revisions,
              modifications, changes and amendments will be posted on the
              Website once occur. It is your covenant to periodically visit the
              Website to review any changes and/or amendments that may be made
              to the Terms.
              <hr />
              YOU AGREE TO CONTINUE USING THE SERVICE, AND COMPANY AGREES TO
              PROVIDE SERVICE IN ACCORDANCE WITH THE FOLLOWING TERMS:
              <hr />
              1. General terms <br />
              When you use services, purchase goods or service, or receive other
              type of assets, (hereinafter “ESPL”) from Esports Pte. Ltd you may
              only do so by accepting the following conditions and, by doing so,
              you warrant and represent that the following are a true and
              accurate reflection of the basis on which you are acquiring
              services:
              <hr />
              <ul>
                <li>
                  neither the Company nor any of the ESPL Team member has
                  provided you with any advice regarding use of services;
                </li>
                <li>
                  you have sufficient understanding of the gaming industry and
                  nature of services provided by ESPL;
                </li>
                <li>
                  you are legally permitted to use our services in your and any
                  other relevant jurisdiction;
                </li>
                <li>
                  you will supply us with all information, documentation or copy
                  documentation that we require in order to allow us to provide
                  you with service, goods and other assets (may that be
                  providing it for free, or selling you for fiat money or other
                  type of assets;
                </li>
                <li>
                  you have not supplied us with information or otherwise which
                  is inaccurate or misleading;
                </li>
                <li>
                  you will provide us with any additional information which may
                  be reasonably required in order that we can fulfil our legal,
                  regulatory and contractual obligations, including but not
                  limited to any anti-money laundering obligation;
                </li>
                <li>
                  you will notify us promptly of any change to the information
                  supplied by you to us;
                </li>
                <li>
                  you are of a sufficient age (if an individual) to legally
                  obtain assets and services from us, and you are not aware of
                  any other legal reason to prevent you from that;
                </li>
                <li>
                  you take sole responsibility for any restrictions and risks
                  associated with receiving services and or assets;
                </li>
                <li>
                  by receiving services, acquiring services, products or other
                  assets, you are not making a regulated investment, as this
                  term may be interpreted by the regulator in your jurisdiction;
                </li>
                <li>
                  you are not obtaining or using assets for any illegal purpose,
                  and will not use assets for any illegal purpose;
                </li>
                <li>
                  you waive any right you may have / obtain to participate in a
                  class action lawsuit or a class wide arbitration against any
                  entity or individual involved with the provision of services;
                </li>
                <li>
                  your acquisition of assets and services not involve your
                  acquisition or receipt of shares, ownership or any equivalent
                  in any existing or future public or private company,
                  corporation or other entity in any jurisdiction.
                </li>
              </ul>
              <hr />
              2. Overview <br />
              ESPL provides user with services in the field of competitive
              gaming, which include but not limited with:
              <hr />
              <ul>
                <li>
                  access to information regarding gaming activities, organised
                  by various industry stakeholders, using our services, or
                  services provided by third-party providers;
                </li>
                <li>
                  gathering and structuring permitted by users and third-party
                  provider information regarding gaming activities;
                </li>
                <li>
                  instruments and tools to collect, show and share information
                  related to competitive gaming activities (e.g. tournaments);
                </li>
                <li>marketplace of goods, services and other assets;</li>
                <li>other services, not listed above.</li>
              </ul>
              <hr />
              The company allows to use services, acquire products and other
              assets companies and physical persons, who are legally allowed to
              do so.
              <hr />
              In order to get detailed information about the project
              proposition, one should register on the web-site. Only people, who
              has passed this stage and in case if they are allowed by their
              legislation, will become users of the platform. We don’t do any
              public solicitation and share details. Any information regarding
              services, products and assets besides information listed in
              documents, publicly available on the Website is considered
              confidential. Sharing any documents, which include details about
              business besides that from any other email is prohibited.
              <hr />
              3. Usage of services <br />
              ESPL will allow using platform within a legal framework. Use cases
              as well as timing and possibility are listed on the site. We
              preserve the right to update this list without additional notice
              <hr />
              4. Privacy <br />
              You may be contacted by email by the Company. Such emails will be
              informational only. The Company will not request any information
              from you in an email. See our Privacy Policy available on the
              Website for additional information
              <hr />
              5. Force Majeure <br />
              We shall not be liable for (1) any inaccuracy, error, delay in, or
              omission of (a) any information, or (b) the transmission or
              delivery of information; (2) any loss or damage arising from any
              event beyond our reasonable control, including but not limited to
              unavoidable casualty, delays in delivery of materials, government
              orders, acts of civil or military authorities, acts by common
              carriers, emergency conditions (including weather conditions),
              security issues arising from the technology used, fire, war,
              insurrection, riot, labour dispute, accident, action of
              government, communications, power failure, or equipment or
              software malfunction or any other cause beyond our reasonable
              control (each, a "Force Majeure Event"). If an event of force
              majeure occurs, the party injured by the other's inability to
              perform may elect to suspend the Terms, in whole or part, for the
              duration of the force majeure circumstances. The party
              experiencing the force majeure circumstances shall cooperate with
              and assist the injured party in all reasonable ways to minimize
              the impact of force majeure on the injured party.
              <hr />
              6. Disclaimer of Warranties <br />
              This Website are provided on an “as is” basis and without any
              warranties of any kind, either expressed or implied. You assume
              all responsibility and risk with respect to your use of the
              Website.
              <hr />
              7. Limitations Waiver of Liability <br />
              You hereby expressly understand and agree, that:
              <hr />
              <ul>
                <li>
                  ESPL should not accept any liability for any illegal or
                  unauthorized use of this Website;
                </li>
                <li>
                  ESPL does not warrant or represent that any information on the
                  Website is accurate or reliable or that the Website will be
                  free of errors or viruses, that defects will be corrected, or
                  that the service or the server that makes it available is free
                  of viruses or other harmful components;
                </li>
                <li>
                  ESPL shall not be liable for the uninterrupted availability of
                  the Website at all times, in all countries and/or all
                  geographic locations, or at any given time;
                </li>
                <li>
                  it is your’s obligation to ensure compliance with any
                  legislation relevant to his/her country of residency
                  concerning the use of this Website.
                </li>
              </ul>
              <hr />
              8. Complete Agreement <br />
              This Terms of Service constitutes the entire agreement between you
              and ESPL and governs your use of the website, superseding any
              prior agreements between you and ESPL with respect to the website
              and ESPL services.
              <hr />
              9. Severability <br />
              You and the Company agree that if any portion of these Terms is
              found illegal or unenforceable, in whole or in part, such
              provision shall, as to such jurisdiction, be ineffective solely to
              the extent of such determination of invalidity or unenforceability
              without affecting the validity or enforceability thereof in any
              other manner or jurisdiction and without affecting the remaining
              provisions of the Terms, which shall continue to be in full force
              and effect.
              <hr />
              10. No Waiver <br />
              The failure of the Company to require or enforce strict
              performance by you of any provision of these Terms or the
              Company’s failure to exercise any right under these Terms shall
              not be construed as a waiver or relinquishment of the Company's
              right to assert or rely upon any such provision or right in that
              or any other instance. The express waiver by the Company of any
              provision, condition, or requirement of these Terms shall not
              constitute a waiver of any future obligation to comply with such
              provision, condition or requirement. Except as expressly and
              specifically set forth in these Terms, no representations,
              statements, consents, waivers, or other acts or omissions by the
              ESPL Team shall be deemed a modification of these Terms nor be
              legally binding.
              <hr />
              11. Updates to the Terms <br />
              The Company reserves the right, at its sole discretion, to change,
              modify, add, or remove portions of the Terms at any time during
              the sale by posting the amended Terms on the Website. You will be
              deemed to have accepted such changes by continuing to use the
              website. The Terms may not be otherwise amended except by express
              consent of both you and the Company.
              <hr />
              12. Cooperation with Legal Authorities <br />
              The Company will cooperate with all law enforcement enquiries,
              subpoenas, or requests provided they are fully supported and
              documented by the law in the relevant jurisdictions. <br />
              ESPL strictly follows AML (Anti-Money Laundering), KYC (Know Your
              Customer) and other banking or government policies and regulations
              in respective jurisdictions. You fully agree to assist ESPL in
              fulfilment of the mentioned regulations and provide any necessary
              information if such is required of you by the authorized
              authority. Please see our Privacy Policy and for the information
              regarding how we collect and use information. Privacy Policy is
              part of these Terms, so please make sure that you read it.
              <hr />
              13. Indemnification <br />
              To the fullest extent permitted by applicable law, you will
              indemnify, defend and hold harmless the ESPL Team from and against
              all claims, demands, actions, damages, losses, costs and expenses
              including but not limited to reasonable attorneys’ fees incurred
              and/or those necessary to successfully establish the right to
              indemnification) that arise from or relate to: (i) your
              responsibilities or obligations under these Terms; (ii) your
              violation of these Terms; or (iii) your violation of any rights of
              any other person or entity.
              <hr />
              14. Security <br />
              You are responsible for implementing reasonable measures for
              securing your ESPL.gg account, including credentials necessary to
              access your account. If your access credentials are lost, you have
              to start the account recovery procedure. Instructions can be found
              at the espl.gg website. The Company is not responsible for any
              losses, costs or expenses relating to lost access credentials.
              <hr />
              15. Language <br />
              Currently, only English versions of any ESPL communications is
              considered official. The English version shall prevail in case of
              differences in translation.
              <hr />
              16. Intellectual property rights <br />
              ESPL has valid, unrestricted and exclusive ownership of rights to
              use the patents, trademarks, trademark registrations, trade names,
              copyrights, know-how, technology and other intellectual property
              necessary to the provision of service and activities generally.
              Unless otherwise indicated by ESPL, all copyright and other any
              intellectual property of the ESPL, all content and other materials
              contained on the Website or provided in connection with ESPL,
              including, without limitation, the intellectual property rights
              for ESPL and all text, graphics, interface, visual interfaces,
              photographs, trademarks, logos, artwork, and computer code,
              design, structure, selection, methods and algorithms,
              coordination, expression and other content connected to ESPL
              (hereinafter – the "ESPL Materials") are the proprietary property
              of ESPL or our licensors, clients or suppliers and are protected
              by international copyright laws, trademark, patent, trade secret,
              and other intellectual property or proprietary rights laws. These
              Terms permit you to use ESPL Materials for your personal,
              non-commercial use only. You must not reproduce, distribute,
              modify, create derivative works of, publicly display, publicly
              perform, republish, download, store or transmit any of the ESPL
              Materials. There are no implied licenses under the Agreement, and
              any rights not expressly granted to you hereunder are reserved by
              ESPL. If for the purpose of any intellectual property right
              registration of ESPL any documentary submission or any other
              action is required from you, you must provide ESPL with the
              respective assistance.
              <hr />
              17. Governing Law and Arbitration <br />
              The Terms, the arbitration clause contained in them, and all
              non-contractual obligations arising in any way whatsoever out of
              or in connection with these Terms are governed by, construed, and
              take effect in accordance with law of the Republic of Singapore.
              Any dispute or difference arising out of or in connection with
              these Terms or the legal relationships established by these Terms,
              including any question regarding its existence, validity or
              termination (“Dispute”), shall be referred to and finally resolved
              by arbitration. The number of arbitrators shall be three. The seat
              of the arbitration shall be Singapore. The language of the
              arbitration shall be English. A dispute arising out of or related
              to these Terms is personal to you and the Company and will be
              resolved solely through individual arbitration and will not be
              hrought as a class arbitration, class action or any other type of
              representative proceeding. There will be no class arbitration or
              arbitration in which an individual attempts to resolve a Dispute
              as a representative of another individual or group of individuals.
              Further, a Dispute cannot be hrought as a class or other type of
              representative action, whether within or outside of arbitration,
              or on behalf of any other individual or group of individuals.
              <hr />
              18. Termination and suspension of services <br />
              Notwithstanding anything contained herein, We reserve the right,
              without notice and at our sole discretion, to terminate these
              Terms or suspend your right to access the Website, including (but
              not limited to) in case of your hreach of these Terms or if ESPL
              believes you have committed fraud, negligence or other misconduct.
              You may terminate these Terms without notice by discontinuing use
              of the Website. All rights granted to you under these Terms will
              immediately be revoked upon our termination of these Terms or our
              suspension of your access to the Website. In the event of any
              Force Majeure Event, hreach of this agreement, or any other event
              that would make provision of given by us commercially unreasonable
              for the ESPL, we may, in our discretion and without liability to
              you, with or without prior notice, suspend your access to all or a
              portion of our services/website. We may terminate your access to
              the Website in our sole discretion, immediately and without prior
              notice, and delete all related information and files without
              liability to you, including, for instance, in the event that you
              hreach any condition of these Terms.
              <hr />
              19. Contact and notices <br />
              You or anyone else may send any questions regarding the use of the
              Website or regarding these Terms via e-mail. Any communication
              concerning these Terms execution and/or violation should be
              conducted only via your official emails and official emails of
              ESPL. The ESPL official email address for communication with you
              is master@espl.gg. Your official email for communication shall be
              deemed the email specified by you during the create an account
              process. We may provide any notice to you under these Terms by:
              (i) posting a notice on the Website; or (ii) sending an email to
              the email address then associated with you. Notices we provide by
              posting on the Website will be effective upon posting and notices
              we provide by email will be effective when we send the email. It
              is your responsibility to keep your email address current. You
              will be deemed to have received any email sent to the email
              address then associated with you when we send the email, whether
              or not you actually receive or read the email.
            </span>
          </h2>
        </div>
        <div className="terms-modal-footer">
          <button
            className="button-transparent"
            onClick={() => {
              setTermsModal(false);
              setAcceptedTerms(false);
            }}
          >
            Decline
          </button>
          <button
            type="button"
            className="btn-primary"
            onClick={() => {
              setAcceptedTerms(true);
              setTermsModal(false);
            }}
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;

import "./App.scss";

import Hero from "./components/Hero";
import Welcome from "./components/Welcome";
import Contacts from "./components/Contacts";
import Footer from "./components/Footer";
import TermsModal from "./components/TermsModal";
function App() {
  return (
    <div className="App">
      <Hero />
      <Welcome />
      <Contacts />
      <Footer />
    </div>
  );
}

export default App;

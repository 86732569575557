import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
} from "amazon-cognito-identity-js";

var poolData = {
  UserPoolId: "ap-southeast-1_DlgtMn9jV", // Your user pool id here
  ClientId: "4bbthdojhc2rcrvdvgubfqithc", // Your client id here
};

const userPool = new CognitoUserPool(poolData);

export const createUser = (
  email,
  password,
  phone,
  country,
  username,
  callback
) => {
  const attributeList = [
    new CognitoUserAttribute({
      Name: "email",
      Value: email,
    }),
    new CognitoUserAttribute({
      Name: "phone_number",
      Value: phone,
    }),
    new CognitoUserAttribute({
      Name: "custom:countryId",
      Value: country,
    }),
  ];

  userPool.signUp(username, password, attributeList, null, callback);
};

export const verifyUser = (email, verifyCode, callback) => {
  const userData = {
    Email: email,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.confirmRegistration(verifyCode, true, callback);
};
